import { useState } from "react";
import OnBoardingModal from "../../components/modalMimics/OnBoardingModal";
import OnBoardingBg from "../../assets/on-boarding-bg.png";
import { onBoardingListData } from "../../dummyData";

const OnBoarding = ({ onFinalStep }) => {
  const [flowAt, setFlowAt] = useState(0);

  return (
    <div
      style={{
        height: "95vh",
        // background: `url(${OnBoardingBg}) rgba(0,0,0,0.6)`,
        backgroundBlendMode: "color",
        backgroundSize: "cover",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <OnBoardingModal
        flowAt={flowAt}
        setFlowAt={setFlowAt}
        onFinish={() => onFinalStep()}
        onNextClick={() => setFlowAt((prev) => prev + 1)}
        showNavBar={onBoardingListData[flowAt]?.showNavBar}
        showWelcomeTitle={onBoardingListData[flowAt]?.showWelcomeTitle}
        subtite={onBoardingListData[flowAt]?.subtitle}
        title={onBoardingListData[flowAt]?.title}
        vectorArt={onBoardingListData[flowAt]?.vectorArt}
      />
    </div>
  );
};

export default OnBoarding;

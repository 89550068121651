import { Input, Label } from "reactstrap";

const CheckBoxInput = ({ value, setValue, label }) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <Input
        type="checkbox"
        checked={value}
        className="m-0 cursor-pointer"
        onChange={(e) => setValue(e?.target?.checked)}
      />
      <Label tag={'small'}  className="f-4 m-0 color-blue ">{label}</Label>
    </div>
  );
};

export default CheckBoxInput;

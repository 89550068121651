import { Input, Label } from "reactstrap"


const IconInput = ({icon,label,value,setValue,className,placeholder,type,disabled,options}) => {
  return (
    <div className={`${className}`}>
     {label&&<small className="f-4 mx-3">{label}</small>}
     <div className="icon-input px-2 py-1">
      {icon}
      <Input
      className="bg-transparent border-transparent"
        value={value}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) =>
          type == "file"
            ? setValue(e.target.files[0])
            : setValue(e.target.value)
        }
      >
        {type == "select" ? (
          <>
            {options?.map((item, k) => (
              <option key={k} selected={k == 0}>
                {typeof item === "object" ? item?.title : item}
              </option>
            ))}
          </>
        ) : null}
      </Input> 
     </div>
    </div>
  )
}

export default IconInput

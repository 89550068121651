import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import NavigationIndicator from "../indicators/NavigationIndicator";
import StandardButton from "../buttons/StandardButton";

const OnBoardingModal = ({
  showWelcomeTitle,
  showNavBar,
  vectorArt,
  title,
  subtite,
  onNextClick,
  flowAt,
  onFinish,
  setFlowAt
}) => {
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  return (
    <Card
      className={` ${theme} py-3`}
      style={{ borderRadius: "48px", width: "50%" }}
    >
      {showWelcomeTitle && (
        <CardHeader
          tag={"h5"}
          className="f-4 border-none text-center bg-transparent"
        >
          Welcome to Heynay
        </CardHeader>
      )}

      <CardBody>
        <Container fluid>
          <Row className="">
            {showNavBar && (
              <NavigationIndicator
                className={"mb-4"}
                flowAt={flowAt - 1}
                totalFlows={6}
              />
            )}
            <Col md={5}>
              <img
                height={180}
                className="full-width object-fit-contain"
                src={vectorArt}
              />
            </Col>
            <Col md={7} className="d-flex align-items-center">
              <div>
                <CardTitle tag={"h4"} className="f-6 my-3">
                  {title}
                </CardTitle>
                <CardSubtitle tag={"p"} className="f-4 m-0">
                  {subtite}
                </CardSubtitle>
              </div>
            </Col>
            <div className="d-flex align-items-center justify-content-center gap-2 mt-5 mb-3 ">
              {flowAt < 1 || flowAt > 5 ? null : (
                <StandardButton
                  prevCaret={true}
                  className="btn btn-grey border-none text-black py-2 full-width"
                  onClick={()=>setFlowAt((prev)=>prev-1)}
                >
                  Previous
                </StandardButton>
              )}
              <StandardButton
                className={`btn-blue  full-width border-none py-2`}
                nextCaret={flowAt > 0 && flowAt < 6}
                onClick={() => (flowAt < 6 ? onNextClick() : onFinish())}
              >
                {flowAt < 1 ? "Get Started" : flowAt < 6 ? "Next" : "Finish"}
              </StandardButton>
            </div>
            <Button
            onClick={()=>onFinish()}
            className="bg-transparent  border-none full-width text-danger f-6">
              Skip all
            </Button>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default OnBoardingModal;

import { useEffect, useState } from "react";
import NormalLayout from "../../layouts/NormalLayout";
import { useDispatch } from "react-redux";
import { appSettings } from "../../redux/action";
import { defaultAppSettings } from "../../Defaults";
import routes from "../../routes";
import Header from "./Header";
import Splash from "../../pages/onBoarding/Splash";
import AuthLayout from "../../layouts/AuthLayout";

const AppRoutes = () => {
  const [flowAt, setFlowAt] = useState("splash");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appSettings(defaultAppSettings));
  }, []);

  //dummy loading splash
  useEffect(() => {
    const splashLoadTimer = setTimeout(() => {
      setFlowAt("on-boarding");
    }, 2000);
    return () => {
      clearInterval(splashLoadTimer);
    };
  }, []);

  const renderLayout = () => {
    switch (flowAt) {
      case "on-boarding":
        return (
          <NormalLayout
            routes={routes?.onBoardingRoutes}
            onFinalStep={() => setFlowAt("auth")}
          />
        );

      case "auth":
        return (
          <AuthLayout
            routes={routes?.authRoutes}
            onFinalStep={() => setFlowAt("")}
          />
        );
    }
  };

  return (
    <>
      {flowAt === "splash" ? (
        <Splash />
      ) : (
        <>
          <Header onLinkClick={(newFlowAt) => setFlowAt(newFlowAt)} />
          {renderLayout()}
        </>
      )}
    </>
  );
};

export default AppRoutes;

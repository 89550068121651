import welcomeVectorArt from "../assets/welcome-vector-art.png";
import communicationVectorArt from "../assets/communication-vector-art.png";
import manageComunityVectorArt from "../assets/manage-comunity-vector-art.png";
import voiceOpinionVectorArt from "../assets/voice-opinion-vector-art.png";
import stayInformedVectorArt from "../assets/stay-informed-vector-art.png";
import securedVectorArt from "../assets/secure-vectort-art.png";
import communityVectorArt from "../assets/community-vector-art.png";

export const onBoardingListData = [
  {
    showWelcomeTitle: true,
    title: `Your private and secure community platform`,
    subtitle: `Stay connected with your neighbors, participate in community events, and keep up with important announcements.`,
    vectorArt: welcomeVectorArt,
  },
  {
    title: `Seamless Communication`,
    subtitle: `Easily communicate with your HOA board members and neighbors through group messaging and announcements.`,
    vectorArt: communicationVectorArt,
    showNavBar: true,
  },
  {
    title: `Manage Community Events`,
    subtitle: `Keep track of neighborhood gatherings, social activities, and board meetings with our event management feature.`,
    vectorArt: manageComunityVectorArt,
    showNavBar: true,
  },
  {
    title: `Voice Your Opinion`,
    subtitle: `Participate in community polls and surveys to share your thoughts and feedback.`,
    vectorArt: voiceOpinionVectorArt,
    showNavBar: true,
  },
  {
    title: `Stay Informed`,
    subtitle: `Receive timely notifications for new posts, events, and important updates from your community.`,
    vectorArt: stayInformedVectorArt,
    showNavBar: true,
  },
  {
    title: `Secure and Private`,
    subtitle: `Your data and communications are protected with our top-notch security features, ensuring privacy for all members.`,
    vectorArt: securedVectorArt,
    showNavBar: true,
  },
  {
    title: `Join Your Community`,
    subtitle: `Get started by registering or logging in to connect with your HOA community today.`,
    vectorArt: communicationVectorArt,
    showNavBar: true,
  },
];

import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

const NormalLayout = ({ routes, onFinalStep, onLinkClick }) => {
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  return (
    <main className={theme}>
      <Routes>
        {routes?.map((item, key) => {
          let Component = item?.component;
          return (
            <Route
              key={key}
              path={item?.path}
              element={<Component onFinalStep={onFinalStep} />}
            />
          );
        })}
        <Route path="*" element={<Navigate to={""} replace />} />
      </Routes>
    </main>
  );
};

export default NormalLayout;

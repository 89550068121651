import {
  Button,
  Collapse,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import logoImg from "../../assets/logo.png";

import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = ({ onLogout, onLinkClick }) => {
  const [isOpen, setisOpen] = useState(false);
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  const [isLoggedIn, setisLoggedIn] = useState(false);

  return (
    <Navbar className={`header ${theme} py-1 px-md-3  `} expand={"md"}>
      <NavbarBrand className="d-flex align-items-center gap-2">
        <img
          src={logoImg}
          height={40}
          width={40}
          className="object-fit-contain"
        />
        <h6 className="f-5 m-0">Heynay</h6>
      </NavbarBrand>
      <NavbarToggler onClick={() => setisOpen((prev) => !prev)}>
        {!isOpen ? <RxHamburgerMenu size={20} /> : <RxCross2 size={20} />}
      </NavbarToggler>
      <Collapse navbar isOpen={isOpen}>
        <Nav navbar className="full-width justify-content-end gap-2">
          {!isLoggedIn ? (
            <>
              <NavItem>
                <Button
                  onClick={() => onLinkClick("auth")}
                  className="border-none btn-grey text-black"
                >
                  Login
                </Button>
              </NavItem>
              <NavItem>
                <Button className=" border-none btn-blue">Register</Button>
              </NavItem>
            </>
          ) : (
            <></>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;

import { useSelector } from "react-redux";
import Header from "../components/globals/Header";
import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect } from "react";

const AuthLayout = ({ routes }) => {
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  return (
    <main className={theme}>
      <Routes>
        {routes?.map((item, key) => {
          let Component = item?.component;
          return <Route key={key} path={item?.path} element={<Component />} />;
        })}
        <Route path="*" element={<Navigate to={'/'} replace/>}/>
      </Routes>
    </main>
  );
};

export default AuthLayout;

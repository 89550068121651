import { Button, Card, CardBody, CardHeader } from "reactstrap";
import NormalInput from "../../components/inputs/NormalInput";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import forgotPasswordVectorArt from "../../assets/forgot-password-vector-art.png";

const ForgotPassword = () => {
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  const navigateTo = useNavigate();
  return (
    <div
      style={{ minHeight: "95vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Card
        className={`${theme}  p-3 `}
        style={{ width: "35%", borderRadius: "48px" }}
      >
        <CardHeader
          tag={"div"}
          className="border-none  bg-transparent text-center"
        >
          <h4 className="f-6">Forgot Your Password?</h4>
          <small className="f-5">
            No worries! Enter your email address, and we'll send you
            instructions to reset your password.
          </small>
        </CardHeader>
        <CardBody>
          <div className="text-center mb-2">
            <img
              alt="forgot-password"
              height={150}
              width={200}
              src={forgotPasswordVectorArt}
              className="object-fit-contain"
            />
          </div>
          <NormalInput
            label={"Email"}
            className={"my-4"}
            placeholder={"example@email.com"}
            setValue={() => {}}
          />
          <div className="d-flex align-items-center gap-2  justify-content-center">
            <Button
              className="btn btn-grey text-danger full-width py-3  f-5 border-none"
              onClick={() => navigateTo(-1)}
            >
              Cancel
            </Button>
            <Button className="btn btn-blue f-5 border-none full-width py-3 ">Submit</Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ForgotPassword;

import ForgotPassword from "./pages/authPages/ForgotPassword";
import Login from "./pages/authPages/Login";
import SetupAccount from "./pages/authPages/SetupAccount";
import OnBoarding from "./pages/onBoarding/OnBoarding";

const routes = {
  onBoardingRoutes: [{ component: OnBoarding, path: "/" },
  ],
  authRoutes: [
    { path: "/", component: Login },
    { path: "/forgotpassword", component: ForgotPassword },
    { path: "/setupaccount", component: SetupAccount },
  ],
};

export default routes;

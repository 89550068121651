import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import NormalInput from "../../components/inputs/NormalInput";
import IconInput from "../../components/inputs/IconInput";
import { CiLock } from "react-icons/ci";
import CheckBoxInput from "../../components/inputs/CheckBoxInput";
import { Link } from "react-router-dom";
import StandardButton from "../../components/buttons/StandardButton";

const Login = () => {
  const { theme } = useSelector((s) => s?.appSettings ?? {});
  return (
    <div
      style={{ minHeight: "95vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Card
        className={`${theme} p-3 `}
        style={{ width: "35%", borderRadius: "48px" }}
      >
        <CardHeader
          tag={"div"}
          className="border-none  bg-transparent text-center"
        >
          <h4 className="f-6">Login to your account</h4>
          <small className="f-5">Please enter your login details</small>
        </CardHeader>
        <CardBody>
          <NormalInput
            className={"mb-2"}
            label={"Email Address"}
            placeholder={"example@email.com"}
            setValue={() => {}}
            type={"email"}
          />
          <IconInput
            label={"Password"}
            setValue={() => {}}
            placeholder={"Minimum 8 characters..."}
            icon={<CiLock size={20} />}
            type={"password"}
            className={"mb-2"}
          />

          <div className="d-flex align-items-center justify-content-between mb-4 mt-3">
            <CheckBoxInput
              setValue={() => {}}
              label={"Remember Me"}
              id={"RememberMe"}
            />

            <Link
              to={"/forgotpassword"}
              className="f-4 p-0 m-0 text-danger text-decoration-none"
            >
              <small>Forgot Password ?</small>
            </Link>
          </div>
          <StandardButton  nextCaret className="btn-blue next-caret full-width border-none py-3 mb-4">
            Login
          </StandardButton>
          <div>
            <small className="d-block f-4 text-center">
              Don’t have an account?
            </small>
            <Link to={'/setupaccount'} className="btn btn-grey py-3 border-none  f-5 full-width ">
              Set Up Account
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Login;

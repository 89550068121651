import { FormGroup, Input, Label } from "reactstrap";

const NormalInput = ({
  value,
  setValue,
  placeholder,
  disabled,
  type,
  label,
  options,
  className,
}) => {
  return (
    <div className={className}>
      {label && <small className="f-4 mx-3">{label}</small>}
      <Input
        value={value}
        type={type}
        disabled={disabled}
        className="py-2"
        placeholder={placeholder}
        onChange={(e) =>
          type == "file"
            ? setValue(e.target.files[0])
            : setValue(e.target.value)
        }
      >
        {type == "select" ? (
          <>
            {options?.map((item, k) => (
              <option key={k} selected={k == 0}>
                {typeof item === "object" ? item?.title : item}
              </option>
            ))}
          </>
        ) : null}
      </Input>
    </div>
  );
};

export default NormalInput;

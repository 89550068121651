import logo from "../../assets/logo.png";

const Splash = () => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex align-items-center  justify-content-center"
    >
      <div className="splash">
        <img
          height={112}
          width={112}
          src={logo}
          className="object-fit-contain"
        />
      </div>
    </div>
  );
};

export default Splash;

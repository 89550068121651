const NavigationIndicator = ({ totalFlows, flowAt, className }) => {
  const flowItems = [];
  for (let i = 0; i < totalFlows; i++) {
    flowItems?.push(
      <div
        className={className}
        style={{
          width: 100,
          height: 4,
          transition: "0.3s linear all",
          background:
            flowAt === i ? "rgba(1, 144, 210, 1)" : "rgba(216, 242, 255, 1)",
        }}
      ></div>
    );
  }
  return <div className="d-flex full-width gap-1">{flowItems}</div>;
};

export default NavigationIndicator;

import { Button } from "reactstrap";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";
const StandardButton = ({
  className,
  style,
  children,
  nextCaret,
  prevCaret,
  onClick,
}) => {
  return (
    <Button
      onClick={() => (onClick ? onClick() : () => {})}
      className={`${className} ${
        nextCaret || prevCaret
          ? "d-flex align-items-center justify-content-center gap-2"
          : ""
      }`}
      style={style}
    >
      {prevCaret && <GrFormPreviousLink size={20} />} {children}
      {nextCaret && <GrFormNextLink size={20} />}
    </Button>
  );
};

export default StandardButton;

const initialValue = {
  allUsers: [],
  user:{},
  appSettings: undefined,

};

const reducerData = (state = initialValue, action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        user: action.payload,
      };
    }
    case "SETTINGS":{
      return {...state,appSettings:action.payload}
    }
    default:
      return state;
  }
};

export default reducerData;
